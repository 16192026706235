import { AttachmentTypeEnum, FileResourceTypeEnum, ReportCheckpoint, ReportCheckpointHeader, ReportDefect } from '../../../backend_api/models';
import { objectHasKeys } from '../../../base/utils';
import { CheckpointRemark, CheckpointRemarks, CheckpointStatusEnum, CheckpointWithRemark } from '../../checklists/types';
import { Defect, Inspection, ReportState } from '../types';


// Derive checkpoints data from report inspection
export const getCheckpointsData = (reportData: ReportState): { checkpoints: ReportCheckpoint[]; remarks: CheckpointRemarks } => { // TODO: Report type should be replaced by ReportInspectionRoot or smth at some point
    const checkpointsObj = {
        hasHeaders: false,
        checkpoints: undefined,
        remarks: undefined,
    }

    const headers = reportData.inspection.checkpoint_headers;
    if (reportData.inspection.checkpoint_headers.length > 0) {
        const hasHeaders = Object.prototype.hasOwnProperty.call(headers[0], 'id') && headers[0].id !== null;
        checkpointsObj.checkpoints = reportData.inspection.checkpoint_headers;
        checkpointsObj.remarks = getCheckpointsRemarks(checkpointsObj.checkpoints, false, hasHeaders, reportData.inspection.sub_inspections);
        checkpointsObj.hasHeaders = hasHeaders;
    }
    return checkpointsObj;
};

export const getCheckpointsRemarks = (checkpointsHeaders: ReportCheckpointHeader[], isSub: boolean, hasHeaders: boolean, subInspections?: Inspection[]): CheckpointRemarks => {
    const STATUS = CheckpointStatusEnum;
    const defaultComments: string[] = ['Not applicable', 'Checked - OK'];
    const defaultStatus: (string | number)[] = [STATUS.CHECKED_OK, STATUS.NOT_SET];
    const alertStatus: (string | number)[] = [STATUS.CHECKED_NOT_OK, STATUS.PENDING];
    const remarksObj = {};
    const remarks = {};
    const getRemarkItems = (checkpointsHeaders: ReportCheckpointHeader[], isSub: boolean, masterChp?: ReportCheckpoint): void => {

        checkpointsHeaders.forEach((checkpointHeader: ReportCheckpointHeader) => {
            const id = checkpointHeader.id ? checkpointHeader.id : 'noHeaderId';
            const checkpointsWithRemarks: CheckpointWithRemark[] = [];
            remarks[id] = remarks[id] ? remarks[id] : []; //Ensure that there is at least an empty list
            checkpointHeader.checkpoints.forEach((checkpoint) => {
                const remarkTypes = [];
                const obj: CheckpointRemark = {
                    imageComments: [],
                    barcode: null,
                    commentObj: null,
                    comment: null,
                    comment2: null,
                    description: [],
                    id: checkpoint.id,
                    hasRemark: false,
                    isAlert: false,
                    defects: null,
                    status: checkpoint.status,
                    multiple_choice: null,
                    status_enabled: checkpoint.status_enabled,
                    has_spacer: checkpoint.has_spacer,
                };
                const currentSubInspection = subInspections ? subInspections.filter((si) => si.inspection_id === checkpoint.inspection_id) : [];
                const orderNumberSame = subInspections ? [...new Set(subInspections.map(item => item.order_number))].length === 1 : false;
                checkpoint.multiple_choice && checkpoint.multiple_choice.options.forEach((option) => {
                    if (option.chosen) {
                        obj.multiple_choice = checkpoint.multiple_choice;
                        if (checkpoint.multiple_choice.options.filter((mp) => mp.chosen && mp.failed).length > 0) {
                            obj.hasRemark = true;
                        }
                    }
                });
                checkpoint.observed_photos && checkpoint.observed_photos.forEach((photo) => {
                    obj.imageComments.push(photo);
                    if (photo.comment && photo.comment !== '' && !defaultStatus.includes(checkpoint.status)) {
                        obj.hasRemark = true;
                        remarkTypes.push('photoComment');
                    }
                });

                if (checkpoint.barcode_table && objectHasKeys(checkpoint.barcode_table)) {
                    checkpoint.barcode_table.barcodes.forEach((barcode) => {
                        if (barcode.scanned_barcode && barcode.expected_barcode && barcode.expected_barcode.value && barcode.expected_barcode.value !== barcode.scanned_barcode) {
                            obj.hasRemark = true;
                            obj.barcode = { observed_value: barcode.scanned_barcode, expected_value: barcode.expected_barcode.value };
                            remarkTypes.push('barcodeMismatch');
                        }
                    })
                }
                if (alertStatus.includes(checkpoint.status)) {
                    obj.isAlert = true;
                    obj.hasRemark = true;
                }
                if (checkpoint.comment2 !== null && !defaultStatus.includes(checkpoint.status)) {
                    obj.hasRemark = true;
                    remarkTypes.push('comment2');
                } else {
                    if (checkpoint.comment2 === null && checkpoint.comment !== null && !defaultComments.includes(checkpoint.comment)) {
                        obj.hasRemark = true;
                        remarkTypes.push('comment');
                    }
                }
                if (checkpoint.defects) {
                    obj.defects = checkpoint.defects;
                }
                if (checkpoint.status && checkpoint.status === 'pending') {
                    obj.hasRemark = true;
                    obj.status = STATUS.PENDING;
                    remarkTypes.push('statusPending');
                }
                obj.description = isSub ? [{ C: currentSubInspection && (orderNumberSame ? (currentSubInspection[0].item_number + '. ' + currentSubInspection[0].item_name || '-') : (currentSubInspection[0].order_number)) || masterChp.instruction_text.C }, checkpoint.instruction_text] : [checkpoint.instruction_text];
                obj.commentObj = { comment: checkpoint.comment, comment2: checkpoint.comment2, comment_language: checkpoint.comment_language, translated_comment2: checkpoint.translated_comment2, status: checkpoint.status };
                obj.comment = checkpoint.comment;
                obj.comment2 = checkpoint.comment2;
                remarks[id].push(obj);
                if (obj.hasRemark) {
                    checkpointsWithRemarks.push({ id: checkpoint.id, remarkTypes });
                }
                if (checkpoint.sub_checkpoints && checkpoint.sub_checkpoints.length > 0) {
                    getRemarkItems([{ checkpoints: checkpoint.sub_checkpoints, id: checkpointHeader.id, score: undefined, sequence: undefined }], true, checkpoint);
                }
            });
            remarksObj[id] = { checkpointsWithRemarks, checkpointHeader, hasHeaders, remarks: Object.values(remarks[id]).filter((arr: []) => arr) };
        });
    }
    getRemarkItems(checkpointsHeaders, false);
    return remarksObj;
};

export const getDefectsSummary = (defects: any): Defect[] => {
    const defectsArr = [];
    Object.values(defects).forEach((dCat: Defect[]) => {
        dCat.forEach((d: Defect) => {
            if (d.whole_lot) {
                defectsArr.push(d);
            }
        });
    });
    return defectsArr;
}

export const getCheckpointsObject = (data: ReportState): ReportState => {
    const checkpointsObj = getCheckpointsData(data);
    data.checkpoints = checkpointsObj.checkpoints;
    data.checkpointsRemarks = checkpointsObj.remarks;
    data.defectsSummary = getDefectsSummary(data.inspection.defects);
    return data;
};


type DefectOrCheckpoint =
    { type: 'defect', defect: ReportDefect } |
    { type: 'checkpoint', checkpoint: ReportCheckpoint }

export const loadDefectsAndCheckpointsImagesForLB = (item: { defects?: Inspection['defects'], checkpoint_headers?: ReportCheckpointHeader[] }): { items: any[]; images: any[] } => {
    // TODO: defects?: Inspection['defects'] should be replaced by proper InspectionReport type once available
    const currentLang = 'en'; // TODO: loadDefectsAndCheckpointsImagesForLB: get currentLang form somewhere else
    const imagesAndVideos: {
        type: 'image' | 'video',
        src?: string,
        autoPlay?: boolean;
        sources?: {
            src: string,
            type: string,
        }[]

    }[] = [];
    const images = imagesAndVideos;

    const items = [];
    const addCorrectiveActions = (element: DefectOrCheckpoint) => {
        if (element.type === 'checkpoint') {
            const checkpoint = element.checkpoint;
            const cas = checkpoint.corrective_actions && checkpoint.corrective_actions;
            cas && cas.length > 0 && cas.map((ca) => {ca.evidence.forEach(evidence => {
                    if(evidence.type == FileResourceTypeEnum.Image) {
                        addImageOrVideo(evidence.image.image_url, FileResourceTypeEnum.Image);
                        addItem({
                            type: 'checkpoint',
                            isEvidence: true,
                            evidenceImage: {
                                url: evidence.image.image_url,
                                ca_status: ca.state
                            },
                            checkpoint,
                            id: checkpoint.id,
                            mediaType: 'image',
                            text: ca.description_of_resolution.text,
                        });
                    } else if(evidence.type == FileResourceTypeEnum.Video) {
                        addImageOrVideo(evidence.video.video_resources[0].url, FileResourceTypeEnum.Video);
                        addItem({
                            type: 'checkpoint',
                            isEvidence: true,
                            evidenceImage: {
                                url: evidence.video.video_resources[0].url,
                                ca_status: ca.state
                            },
                            checkpoint,
                            id: checkpoint.id,
                            mediaType: 'video',
                            text: ca.description_of_resolution.text,
                        });
                    }
                })
            })
        }
        if (element.type === 'defect') {
            const defect = element.defect;
            const cas = defect.corrective_actions && defect.corrective_actions;
            cas && cas.length > 0 && cas.map((ca) => {
                ca.evidence.forEach(evidence => {
                    if(evidence.type == FileResourceTypeEnum.Image) {
                        addImageOrVideo(evidence.image.image_url, FileResourceTypeEnum.Image);
                        addItem({
                            type: 'defect',
                            isEvidence: true,
                            evidenceImage: {
                                url: evidence.image.image_url,
                                ca_status: ca.state
                            },
                            defect,
                            severity: defect.severity,
                            id: defect.id,
                            quantity_affected: defect.quantity_affected,
                            whole_lot: defect.whole_lot,
                            mediaType: 'image',
                            text: ca.description_of_resolution.text,
                        });
                    } else if(evidence.type == FileResourceTypeEnum.Video) {
                        addImageOrVideo(evidence.video.video_resources[0].url, FileResourceTypeEnum.Video);
                        addItem({
                            type: 'defect',
                            isEvidence: true,
                            evidenceImage: {
                                url: evidence.video.video_resources[0].url,
                                ca_status: ca.state
                            },
                            defect,
                            severity: defect.severity,
                            id: defect.id,
                            quantity_affected: defect.quantity_affected,
                            whole_lot: defect.whole_lot,
                            mediaType: 'video',
                            text: ca.description_of_resolution.text,
                        });
                    }
                })
            })
        }
    }
    if (item) {
        if (item.defects) {
            const defects = item.defects;
            if (defects.critical && defects.critical.length > 0) {
                defects.critical.map((defect) => {
                    defect.photos.map((imageUrl) => {
                        addImageOrVideo(imageUrl, 'image');
                        const item: any = {
                            mediaType: 'image',
                            imageUrl: imageUrl,
                            id: defect.id,
                            description: defect.description,
                            severity: defect.severity,
                            quantity_affected: defect.quantity_affected,
                            whole_lot: defect.whole_lot,
                            type: 'defect',
                            currentLang,
                            defect: defect,
                        };
                        if (defect.type) {
                            item.defectType = defect.type;
                        }
                        addItem(item);
                    });
                    addCorrectiveActions({ defect, type: 'defect' });
                });
            }
            if (defects.major && defects.major.length > 0) {
                defects.major.map((defect) => {
                    defect.photos.map((imageUrl) => {

                        addImageOrVideo(imageUrl, 'image');
                        const item: any = {
                            mediaType: 'image',
                            imageUrl: imageUrl,
                            id: defect.id,
                            description: defect.description,
                            severity: defect.severity,
                            quantity_affected: defect.quantity_affected,
                            whole_lot: defect.whole_lot,
                            type: 'defect',
                            currentLang,
                            defect: defect,
                        };
                        if (defect.type) {
                            item.defectType = defect.type;
                        }
                        addItem(item);
                    });
                    addCorrectiveActions({ defect, type: 'defect' });
                });
            }
            if (defects.minor && defects.minor.length > 0) {
                defects.minor.map((defect) => {
                    defect.photos.map((imageUrl) => {

                        addImageOrVideo(imageUrl, 'image');
                        const item: any = {
                            mediaType: 'image',
                            imageUrl: imageUrl,
                            id: defect.id,
                            description: defect.description,
                            severity: defect.severity,
                            quantity_affected: defect.quantity_affected,
                            whole_lot: defect.whole_lot,
                            type: 'defect',
                            currentLang,
                            defect: defect,
                        };
                        if (defect.type) {
                            item.defectType = defect.type;
                        }
                        addItem(item);
                    });
                    addCorrectiveActions({ defect, type: 'defect' });
                });
            }
        }

        item.checkpoint_headers.map((checkpoints) => {
            checkpoints.checkpoints.map((checkpoint) => {

                if(checkpoint.instruction_files?.length > 0) {
                    checkpoint.instruction_files.forEach(file => {
                        if(file.type == AttachmentTypeEnum.Image) {
                            addImageOrVideo(file.url, FileResourceTypeEnum.Image);
                        }
                    })
                }

                if (checkpoint.observed_photos && checkpoint.observed_photos.length > 0) {
                    checkpoint.observed_photos.map((observedPhoto) => {

                        const item = {
                            mediaType: observedPhoto.type,
                            currentLang,
                            imageUrl: observedPhoto.url,
                            id: checkpoint.id,
                            description: checkpoint.instruction_text.en,
                            severity: '-',
                            type: 'checkpoint',
                            imageComment: (() => {
                                const ret = {};
                                ret['translated_comment'] = observedPhoto.translated_comment;
                                ret['comment'] = observedPhoto.comment;
                                ret['comment_language'] = observedPhoto.language;
                                return ret;
                            })(),
                            imageComment2: ((): string => {
                                if (observedPhoto.translated_comment && observedPhoto.translated_comment[currentLang]) {
                                    return observedPhoto.translated_comment[currentLang];
                                }
                                return observedPhoto.comment;
                            })(),
                            status: checkpoint.status,
                            translated_comment2: checkpoint.translated_comment2,
                            comment: checkpoint.comment,
                            comment2: ((): string => {
                                if (checkpoint.comment2) {
                                    return checkpoint.comment2;
                                }
                            })(),
                        };
                        addItem(item);
                        if (observedPhoto.type !== 'video') {
                            addImageOrVideo(observedPhoto.url, 'image');
                        } else {
                            addImageOrVideo(observedPhoto.video_resources[0].url, 'video');
                        }
                    });
                }
                checkpoint.defects && checkpoint.defects.map((checkpointDefect) => {
                    addCorrectiveActions({ defect: checkpointDefect, type: 'defect' });
                    checkpointDefect.photos.map((imageUrl) => {

                        addImageOrVideo(imageUrl, 'image');
                        const item: any = {
                            mediaType: 'image',
                            imageUrl,
                            id: checkpointDefect.id,
                            description: checkpointDefect.description,
                            severity: checkpointDefect.severity,
                            quantity_affected: checkpointDefect.quantity_affected,
                            whole_lot: checkpointDefect.whole_lot,
                            type: 'defect',
                            currentLang,
                        };
                        if (checkpointDefect.type) {
                            item.defectType = checkpointDefect.type;
                        }
                        addItem(item);
                    });
                });

                checkpoint.sub_checkpoints && checkpoint.sub_checkpoints.map((subCheckpoint) => {

                    if (subCheckpoint.observed_photos && subCheckpoint.observed_photos.length > 0) {
                        subCheckpoint.observed_photos.map((observedPhoto) => {
                            const item = {
                                mediaType: observedPhoto.type,
                                imageUrl: observedPhoto.url,
                                id: subCheckpoint.id,
                                description: subCheckpoint.instruction_text.en,
                                severity: '-',
                                type: 'checkpoint',
                                imageComment: (() => {
                                    const ret = {};
                                    ret['translated_comment'] = observedPhoto.translated_comment;
                                    ret['comment'] = observedPhoto.comment;
                                    ret['comment_language'] = observedPhoto.language;
                                    return ret;
                                })(),
                                status: checkpoint.status,
                                translated_comment2: checkpoint.translated_comment2,
                                comment: subCheckpoint.comment,
                                comment2: ((): string => {
                                    if (subCheckpoint.comment2) {
                                        return subCheckpoint.comment2;
                                    }
                                })(),
                            };
                            addItem(item);
                            if (observedPhoto.type !== 'video') {
                                addImageOrVideo(observedPhoto.url, 'image');
                                /* const item = {
                                    imageUrl: observedPhoto.url,
                                    id: subCheckpoint.id,
                                    description: subCheckpoint.instruction_text.en,
                                    severity: '-',
                                    type: 'checkpoint',
                                    imageComment: observedPhoto.comment,
                                    status: checkpoint.status,
                                    translated_comment2: checkpoint.translated_comment2,
                                    comment: subCheckpoint.comment,
                                    comment2: ((): string => {
                                        if (subCheckpoint.comment2) {
                                            return subCheckpoint.comment2;
                                        }
                                    })(),
                                };
                                addItem(item); */
                            } else {
                                addImageOrVideo(observedPhoto.video_resources[0].url, 'video');
                            }
                        });
                    }
                    subCheckpoint.defects.map((checkpointDefect) => {
                        addCorrectiveActions({ defect: checkpointDefect, type: 'defect' });
                        checkpointDefect.photos.map((imageUrl) => {
                            addImageOrVideo(imageUrl, 'image');
                            const item: any = {
                                mediaType: 'image',
                                imageUrl,
                                id: checkpointDefect.id,
                                description: checkpointDefect.description,
                                severity: checkpointDefect.severity,
                                quantity_affected: checkpointDefect.quantity_affected,
                                whole_lot: checkpointDefect.whole_lot,
                                type: 'defect',
                                currentLang,
                            };
                            if (checkpointDefect.type) {
                                item.defectType = checkpointDefect.type;
                            }
                            addItem(item);
                        });
                    });
                    if (subCheckpoint.instruction_text) {
                        // do nothing?
                    }
                    subCheckpoint.corrective_actions && subCheckpoint.corrective_actions.length > 0 && addCorrectiveActions({ checkpoint: subCheckpoint, type: 'checkpoint' });
                });
                checkpoint.corrective_actions && checkpoint.corrective_actions.length > 0 && addCorrectiveActions({ checkpoint, type: 'checkpoint' });
            });
        });
    }
    return { items, images };

    function addItem(item): void {
        items.push(item);
    }
    function addImageOrVideo(imageUrl: string, type?: 'image' | 'video'): void {
        if(imagesAndVideos.findIndex(file => file.src === imageUrl) != -1) {
            return;
        }

        if (type === 'image') {
            imagesAndVideos.push({ type: 'image', src: imageUrl });
        } else {
            imagesAndVideos.push({

                type: 'video',
                autoPlay: true,
                sources: [
                    {
                        src: imageUrl,
                        type: 'video/mp4',
                    }],

            });
        }
    }
};
